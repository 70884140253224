<template>
  <v-dialog v-model="dialog" persistent max-width="1200px" id="frm_member">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="success"
        dark
        class="mb-2"
        @click="AddNew"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon class="mr-2">mdi-account-plus</v-icon> เพิ่มสมาชิกใหม่
      </v-btn>
    </template>
    <form @submit.prevent="submitData">
      <v-card>
        <v-card-title class="font-main">ข้อมูลสมาชิก</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" lg="2">
              <v-text-field
                v-model="dataFrm.mem_code"
                label="รหัสสมาชิก"
                required
                :counter="8"
                maxlength="8"
                :append-outer-icon="statIconcheckMemID"
                @keyup="checkMemCode"
                :success="colorStyle_mem_code && statAC === 'Edit'"
                :error="!colorStyle_mem_code"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="2">
              <v-select
                v-model="dataFrm.mem_prename"
                :items="this.$store.getters.getlistPrename"
                label="คำนำหน้าชื่อ"
                item-value="PreID"
                item-text="PreName"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4"
              ><v-text-field
                v-model="dataFrm.mem_fname"
                label="ชื่อ"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4"
              ><v-text-field
                v-model="dataFrm.mem_lname"
                label="นามสกุล"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="2">
              <v-select
                v-model="dataFrm.mem_province"
                :items="this.$store.getters.getlistProvince || []"
                item-value="ProID"
                item-text="ProName"
                label="จังหวัด"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="2">
              <v-select
                v-model="dataFrm.mem_district"
                :items="
                  this.$store.getters.getlistDistrict({
                    ProID: this.dataFrm.mem_province,
                  }) || []
                "
                item-text="DisName"
                item-value="DisID"
                label="อำเภอ"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="2"
              ><v-select
                v-model="dataFrm.mem_locality"
                :items="
                  this.$store.getters.getlistLocality({
                    ProID: this.dataFrm.mem_province,
                    DisID: this.dataFrm.mem_district,
                  }) || []
                "
                item-text="LocName"
                item-value="LocID"
                label="ตำบล"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="2">
              <v-text-field
                v-model="dataFrm.mem_address"
                label="ที่อยู่"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="2">
              <v-select
                v-model="dataFrm.mem_villate"
                :items="
                  this.$store.getters.getlistVillate({
                    ProID: this.dataFrm.mem_province,
                    DisID: this.dataFrm.mem_district,
                    LocID: this.dataFrm.mem_locality,
                  }) || []
                "
                item-text="vil_name"
                item-value="vil_id"
                label="หมู่บ้าน"
                @change="getMoo"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="2">
              <v-text-field
                v-model="dataFrm.mem_moo"
                label="หมู่ที่"
                type="number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="3"
              ><v-text-field
                :success="colorStyle_mem_people_id"
                :error="!colorStyle_mem_people_id"
                v-model="dataFrm.mem_people_id"
                :counter="13"
                maxlength="13"
                label="เลขบัตรประชาชน"
                required
                :append-outer-icon="statIconcheckPeopleID"
                @keyup="checkPepleID"
                :disabled="statAC == 'Edi'"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="2"
              ><v-text-field
                v-model="dataFrm.mem_phone"
                :counter="10"
                maxlength="10"
                label="เบอร์โทรศัพท์"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="2">
              <v-menu
                v-model="dateB"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormattedBirth"
                    label="วันเดือนปีเกิด"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    readonly
                    required
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dataFrm.mem_birthday"
                  @blur="dateB = parseDate(dateFormatted)"
                  :first-day-of-week="0"
                  locale="th-th"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" lg="2">
              <v-menu
                v-model="dateR"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormattedRegis"
                    label="วันที่สมัครสมาชิก"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    readonly
                    required
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dataFrm.mem_regis_date"
                  @blur="dateR = parseDate(dateFormatted)"
                  :first-day-of-week="0"
                  locale="th-th"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" lg="3"
              ><v-text-field
                v-model="dataFrm.mem_receipt"
                label="ผู้รับผลประโยชน์"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-autocomplete
            v-model="dataFrm.mem_head_line"
            :items="$store.getters.getlistVillateAll"
            label="สังกัดตำบล"
            outlined
            dense
            clearable
            color="red"
            auto-select-first
            item-value="vloc_id"
            item-text="vloc_name"
          ></v-autocomplete>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2"
            type="submit"
            color="success"
            large
            :disabled="
              !(
                (colorStyle_mem_people_id && colorStyle_mem_code) ||
                statAC == 'Edit'
              )
            "
          >
            บันทึก
          </v-btn>
          <v-btn @click="close()" large> ปิดหน้านี้ </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
export default {
  name: "frm_member",
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      dateR: "",
      dateB: "",
      listDis: [],
      listLoc: [],
      statIconcheckPeopleID: "mdi-close-circle",
      statIconcheckMemID: "mdi-close-circle",
      colorStyle_mem_people_id: false,
      colorStyle_mem_code: false,
    };
  },
  props: {
    dialog: { type: Boolean, default: false },
    statAC: { type: String, default: "AddNew" },
    dataFrm: {
      mem_id: "",
      mem_code: "",
      mem_prename: "03",
      mem_fname: "",
      mem_lname: "",
      mem_address: "",
      mem_moo: null,
      mem_villate: "",
      mem_locality: "01",
      mem_district: "10",
      mem_province: "50",
      mem_phone: "",
      mem_birthday: new Date().toISOString().substr(0, 10),
      mem_regis_date: new Date().toISOString().substr(0, 10),
      mem_head_line: "",
    },
  },
  computed: {
    computedDateFormattedBirth() {
      return this.formatDate(this.dataFrm.mem_birthday);
    },
    computedDateFormattedRegis() {
      return this.formatDate(this.dataFrm.mem_regis_date);
    },
  },
  methods: {
    getMoo() {
      let a = this.$store.getters.getlistVillate({
        ProID: this.dataFrm.mem_province,
        DisID: this.dataFrm.mem_district,
        LocID: this.dataFrm.mem_locality,
      });
      let b = a.find((val) => val.vil_id == this.dataFrm.mem_villate)?.vil_moo;
      console.log("getMoo", b);
      this.dataFrm.mem_moo = b;
    },
    AddNew() {
      this.$emit("AddNew");
    },
    close() {
      this.$emit("setClear");
      this.colorStyle_mem_people_id = false;
      this.colorStyle_mem_code = false;
      this.state_BTN_submit = true;
      this.state_BTN_submit2 = true;
      this.dataFrm = {
        mem_id: "",
        mem_code: "",
        mem_prename: "03",
        mem_fname: "",
        mem_lname: "",
        mem_address: "",
        mem_moo: null,
        mem_villate: "",
        mem_locality: "01",
        mem_district: "10",
        mem_province: "50",
        mem_phone: "",
        mem_birthday: new Date().toISOString().substr(0, 10),
        mem_regis_date: new Date().toISOString().substr(0, 10),
        mem_head_line: "",
      };
    },
    submitData() {
      let vData = {};
      Object.assign(vData, this.$props.dataFrm, {
        ad_id: this.$store.getters.checkLogin.mem_id,
      });
      window.$.post(
        this.$store.state.pathAPI + "member/Update_Member",
        vData,
        (param) => {
          //console.log(param);
          window.Swal.fire({
            title: param.title,
            html: param.html,
            icon: param.icon,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$store.dispatch("getlistMember");
        },
        "json"
      );
      this.$emit("setClear");
    },
    checkPepleID() {
      if (this.dataFrm.mem_people_id.trim().length == 13) {
        this.$store.state.statShowLoading = false;
        window.$.post(
          this.$store.state.pathAPI + "member/checkPeopleID",
          {
            mem_people_id: this.dataFrm.mem_people_id,
            statAC: this.statAC,
            mem_id: this.dataFrm.mem_id,
          },
          (param) => {
            if (param.length > 0) {
              this.statIconcheckPeopleID = "mdi-close-circle";
              this.colorStyle_mem_people_id = false;
            } else {
              this.statIconcheckPeopleID = "mdi-check-circle";
              this.colorStyle_mem_people_id = true;
            }
          },
          "json"
        );
      } else {
        this.statIconcheckPeopleID = "mdi-close-circle";
        this.colorStyle_mem_people_id = false;
      }
    },
    checkMemCode() {
      if (this.dataFrm.mem_code.trim().length == 8) {
        this.$store.state.statShowLoading = false;
        window.$.post(
          this.$store.state.pathAPI + "member/checkMemCode",
          {
            mem_code: this.dataFrm.mem_code,
            statAC: this.statAC,
            mem_id: this.dataFrm.mem_id,
          },
          (param) => {
            if (param.length > 0) {
              this.statIconcheckMemID = "mdi-close-circle";
              this.colorStyle_mem_code = false;
            } else {
              this.statIconcheckMemID = "mdi-check-circle";
              this.colorStyle_mem_code = true;
            }
          },
          "json"
        );
      } else {
        this.statIconcheckMemID = "mdi-close-circle";
        this.colorStyle_mem_code = false;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/` + (parseInt(year) + 543).toString();
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
};
</script>

<style></style>
