<template>
  <div class="container--fluid pt-3">
    <v-card>
      <v-card-title>
        ข้อมูลสมาชิก
        <v-divider class="mx-4" inset vertical></v-divider>
        <Frm_member
          :dialog="dialog"
          :statAC="statAC"
          :dataFrm="dataChild"
          @setClear="setClear"
          @AddNew="AddNew"
        ></Frm_member>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="gray" @click="onExport">Export</v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.$store.getters.getlistMember"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on"
                ><v-icon>mdi-cogs</v-icon> จัดการข้อมูล
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link @click="AuthenEdit(item, 'Edit')">
                <v-list-item-title
                  ><v-icon>mdi-pencil</v-icon> แก้ไขข้อมูล</v-list-item-title
                >
              </v-list-item>
              <v-list-item link @click="AuthenEdit(item, 'Reject')">
                <v-list-item-title
                  ><v-icon>mdi-exit-to-app</v-icon>
                  จำหน่ายสมาชิก</v-list-item-title
                >
              </v-list-item>
              <v-list-item link @click="AuthenEdit(item, 'Delete')">
                <v-list-item-title
                  ><v-icon>mdi-trash-can</v-icon> ลบข้อมูล</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        
      </v-data-table>
    </v-card>
    <AuthenEditData
      :dialogAuthenEdit="dialogAuthenEdit"
      :dataChild="dataChild"
      :AcType="AcType"
      @ResultAuthen="choiseAc"
    ></AuthenEditData>
    <Frm_member_lose
      :dialogfrm_member_lose="dialogfrm_member_lose"
      :dataFrm="dataChild"
      @callbackAction="setClear"
    ></Frm_member_lose>
  </div>
</template>

<script>
import Frm_member_lose from "../components/frm_member_lose";
import AuthenEditData from "../components/authenEditData";
import Frm_member from "../components/frm_member";
import XLSX from "xlsx";

export default {
  name: "member",
  components: {
    Frm_member_lose,
    AuthenEditData,
    Frm_member,
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "รหัสสมาชิก",
          align: "start",
          value: "mem_code",
        },
        { text: "ชื่อ-นามสกุล", value: "mem_fullname" },
        { text: "เลขบัตรประชาชน", value: "mem_people_id" },
        { text: "ตำบล,หมู่บ้าน", value: "LocName2" },
        { text: "เบอร์โทร", value: "mem_phone" },
        { text: "สังกัดตำบล", value: "mem_head_name" },
        { text: "จัดการ", value: "actions" },
      ],
      statAC: "AddNew",
      dataChild: {},
      dialog: false,
      dialogAuthenEdit: false,
      dialogfrm_member_lose: false,
      AcType: "",
    };
  },
  async mounted() {
    this.$store.dispatch("getlistMember");
    this.$store.dispatch("getlistMemberLose");
  },
  methods: {
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(
        this.$store.getters.getlistMember
      );
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "member.xlsx");
    },
    selHeadLine(ProID, DIsID, LocID) {
      let vData = {};
      Object.assign(vData, { ProID: ProID, DisID: DIsID });
      // LocID;
      //return vData;
      // console.log(this.$store.getters?.getlistLocality(vData));
      return (
        this.$store.getters
          ?.getlistLocality(vData)
          .find((val) => val.LocID == LocID).LocName || ""
      );
    },
    setClear() {
      this.dialog = false;
      this.dialogfrm_member_lose = false;
      this.statAC = "AddNew";
      this.dataChild = {};
    },
    AuthenEdit(item, type) {
      this.setClear();
      this.AcType = type;
      window.$.post(
        this.$store.state.pathAPI + "DB/list_Member_Once",
        { mem_id: item.mem_id },
        (param) => {
          this.dataChild = param[0];
        },
        "json"
      );
      this.dataChild = item;
      this.dialogAuthenEdit = true;
    },
    choiseAc(item) {
      this.AcType = "";
      this.dataChild = {};
      this.dialogAuthenEdit = false;
      if (item.type === "Edit") {
        this.editItem(item.item);
      }
      if (item.type === "Delete") {
        this.DeleteItem(item.item);
      }
      if (item.type === "Reject") {
        this.RejectItem(item.item);
      }
    },
    AddNew() {
      this.dialog = true;
      this.statAC = "AddNew";
      this.dataChild = {
        mem_id: "",
        mem_prename: "03",
        mem_fname: "",
        mem_lname: "",
        mem_address: "",
        mem_locality: "01",
        mem_district: "10",
        mem_province: "50",
        mem_head_line: "",
        mem_phone: "",
        mem_birthday: new Date().toISOString().substr(0, 10),
        mem_regis_date: new Date().toISOString().substr(0, 10),
      };
    },
    editItem(item) {
      //console.log(item);
      this.dialog = true;
      this.statAC = "Edit";
      this.dataChild = item;
    },
    DeleteItem(item) {
      window.Swal.fire({
        title: "ยืนยันการลบข้อมูล",
        text: "ต้องการลบข้อมูลสมาชิกรายนี้ใช่หรือไม่?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "ใช่ ,ต้องการลบ",
        cancelButtonText: "ไม่ ,ยังไม่ลบ",
        reverseButtons: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.$.post(
            this.$store.state.pathAPI + "member/Del_Member",
            { mem_id: item.mem_id },
            (param) => {
              this.$store.dispatch("getlistMember");
              this.$store.dispatch("getlistVillate");
              window.Swal.fire({
                title: param.title,
                html: param.html,
                icon: param.icon,
                position: "top-end",
                showConfirmButton: false,
                timer: 1500,
              });
            },
            "json"
          );
        }
      });
    },
    RejectItem(item) {
      this.dialogfrm_member_lose = true;
      this.dataChild = item;
    },
  },
};
</script>

<style></style>
