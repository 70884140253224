<template>
  <div id="frm_member_lose">
    <v-dialog v-model="dialogfrm_member_lose" width="600">
      <v-card>
        <v-card-title class="font-main grey lighten-2">
          จำหน่ายสมาชิก
        </v-card-title>
        <form @submit.prevent="submitData()">
          <v-card-text>
            <v-row>
              <v-col cols="12" class="red--text text-center">
                <h2>กรุณาตรวจสอบวันที่เกิด และวันที่สมัครสมาชิก ก่อนยืนยัน</h2>
              </v-col>
              <v-col cols="12" md="6">
                <h3>วันเกิด : {{convDate($props.dataFrm.mem_birthday)}}</h3>
              </v-col>
              <v-col cols="12" md="6">
                <h3>วันสมัคร : {{convDate($props.dataFrm.mem_regis_date)}}</h3>
              </v-col>
              <v-col cols="12" md="6"
                ><v-select
                  v-model="dataFrm.sm_type_eject"
                  :items="this.$store.getters.getlistMemberStatus"
                  label="ประเภทการพ้นสภาพ"
                  item-text="sm_type_name"
                  item-value="sm_type_eject"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" md="6"
                ><v-menu
                  v-model="date_sm_date_eject"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="sm_date_eject"
                      label="วันที่พ้นสภาพ"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dataFrm.sm_date_eject"
                    no-title
                    @blur="date_sm_date_eject = parseDate(dateFormatted)"
                    min="1900-01-01"
                    :first-day-of-week="0"
                    locale="th-th"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" v-if="dataFrm.sm_type_eject == 3"
                ><v-menu
                  v-model="date_sm_dead_date"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="sm_dead_date"
                      label="วันที่เสียชีวิต"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dataFrm.sm_dead_date"
                    no-title
                    @blur="date_sm_dead_date = parseDate(dateFormatted)"
                    min="1900-01-01"
                    :first-day-of-week="0"
                    locale="th-th"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" v-if="dataFrm.sm_type_eject == 3"
                ><v-menu
                  v-model="date_sm_dead_date_paper"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="sm_dead_date_paper"
                      label="วันที่เสียชีวิตในใบมรณบัตร"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dataFrm.sm_dead_date_paper"
                    no-title
                    @blur="date_sm_dead_date_paper = parseDate(dateFormatted)"
                    min="1900-01-01"
                    :first-day-of-week="0"
                    locale="th-th"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" v-if="dataFrm.sm_type_eject == 3"
                ><v-text-field
                  v-model="dataFrm.sm_dead_id"
                  label="หมายเลขการเสียชีวิต"
                ></v-text-field> </v-col
              ><v-col cols="12"
                ><v-text-field
                  v-model="dataFrm.sm_remark"
                  label="หมายเหตุการจำหน่าย"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              type="submit"
              :disabled="dataFrm.sm_date_eject == undefined"
            >
              บันทึกพ้นสภาพ
            </v-btn>
            <v-btn @click="close"> ปิดหน้านี้ </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_call_money_period" persistent max-width="400px">
      <v-card>
        <v-card-title primary-title>
          ยืนยันยอดเงินจ่ายให้กับผู้รับเงิน
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="money_for_mem_lose"
            label="จำนวนเงิน (บาท)"
            type="number"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveDialog_call_money_period" large
            >ยืนยัน</v-btn
          >
          <v-btn large @click="closeDialog_call_money_period">ยกเลิก</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  name: "frm_member_lose",
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      date_sm_dead_date_paper: "",
      date_sm_dead_date: "",
      date_sm_date_eject: "",
      money_for_mem_lose: "0",
      dialog_call_money_period: false,
    };
  },
  props: {
    dialogfrm_member_lose: { type: Boolean, default: false },
    dataFrm: {
      sm_dead_id: "",
      sm_dead_date_paper: "",
      sm_dead_date: "",
      sm_date_eject: {
        type: String,
        default: new Date().toISOString().substr(0, 10),
      },
      sm_type_eject: { type: String, default: "3" },
    },
  },
  computed: {
    sm_dead_date_paper() {
      return this.formatDate(this.dataFrm.sm_dead_date_paper);
    },
    sm_dead_date() {
      return this.formatDate(this.dataFrm.sm_dead_date);
    },
    sm_date_eject() {
      return this.formatDate(this.dataFrm.sm_date_eject);
    },
  },
  methods: {
    convDate(date) { 
      return moment(date).add(543, 'years').format('DD/MM/YYYY');
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/` + (parseInt(year) + 543).toString();
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    rejectType3() {
      this.dialog_call_money_period = true;
      this.$store.dispatch("getlistPeriodOpen").then((res) => {
        this.money_for_mem_lose = res[0].money_for_mem_lose;
      });
    },
    saveDialog_call_money_period() {
      this.$store
        .dispatch("setMoneyPeriodLose", this.money_for_mem_lose)
        .then(() => {
          this.dialog_call_money_period = false;
          window.Swal.fire({
            title: "ยืนยันข้อมูลการจำหน่าย?",
            html:
              'การจำหน่ายประเภท "เสียชีวิต"<br>จะไม่สามารถเรียกคืนสถานะได้อึก',
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: " Yes ",
          }).then((result) => {
            if (result.isConfirmed) {
              this.submitData2();
            } else {
              return;
            }
          });
        });
    },
    closeDialog_call_money_period() {
      this.dialog_call_money_period = false;
    },
    async submitData() {
      if (this.dataFrm.sm_type_eject == 3) {
        this.rejectType3();
      } else {
        this.submitData2();
      }
    },
    submitData2() {
      if (this.dataFrm.sm_date_eject !== "") {
        let vData = {};
        Object.assign(vData, this.dataFrm, {
          ad_id: this.$store.getters.checkLogin.mem_id,
        });
        window.$.post(
          this.$store.state.pathAPI + "member_lose/updateData",
          vData,
          async (param) => {
            window.Swal.fire({
              title: param.title,
              html: param.html,
              icon: param.icon,
            });
            this.$store.dispatch("getlistMember");
            this.close();
          },
          "json"
        );
      } else {
        window.Swal.fire("กรุณาระบุวันที่พ้นสภาพด้วย!!!");
      }
    },
    close() {
      this.$emit("callbackAction");
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
};
</script>

<style></style>
